var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Header'),_vm._m(0),_c('div',[_c('div',{staticClass:"container"},[_c('div',{},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('v-row',[_c('v-col',[_c('div',{staticClass:"input-group-meta form-group mb-60"},[_c('p',[_vm._v("Name")]),_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.name),expression:"formData.name"}],attrs:{"type":"text","placeholder":"Name"},domProps:{"value":(_vm.formData.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "name", $event.target.value)}}}),_c('span',{staticClass:"input-error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('div',{staticClass:"input-group-meta mb-80 sm-mb-70"},[_c('p',[_vm._v("What features are you enjoying the most? ")]),_c('ValidationProvider',{attrs:{"name":"question1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.question1),expression:"formData.question1"}],attrs:{"type":"text","placeholder":"Comment"},domProps:{"value":(_vm.formData.question1)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "question1", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"input-error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('div',{staticClass:"input-group-meta mb-80 sm-mb-70"},[_c('p',[_vm._v("What functionality would you want to be added to the Heubot Application?")]),_c('ValidationProvider',{attrs:{"name":"question2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.question2),expression:"formData.question2"}],attrs:{"placeholder":"Comment"},domProps:{"value":(_vm.formData.question2)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "question2", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"input-error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('div',{staticClass:"input-group-meta mb-80 sm-mb-70"},[_c('p',[_vm._v("Would you Recommended Heubot to a friend or colleague?")]),_c('ValidationProvider',{attrs:{"name":"question3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.question3),expression:"formData.question3"}],attrs:{"placeholder":"Comment"},domProps:{"value":(_vm.formData.question3)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "question3", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"input-error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('div',{staticClass:"input-group-meta mb-80 sm-mb-70"},[_c('p',[_vm._v("Are there any additional comments or questions you have about the Heubot Application? ")]),_c('ValidationProvider',{attrs:{"name":"question4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.question4),expression:"formData.question4"}],attrs:{"placeholder":"Comment"},domProps:{"value":(_vm.formData.question4)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "question4", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"input-error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('button',{staticClass:"theme-btn-home",attrs:{"type":"submit","value":"submit"}},[_vm._v(" SUBMIT ")])])])],1)],1)]}}])})],1)])]),_c('Footer')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fancy-hero-feedback bg-doc space-fix"},[_c('div',{staticClass:"bg-wrapper"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-9 col-lg-11 col-md-10 m-auto"},[_c('h2',[_vm._v("Feedback")]),_c('h1',{staticClass:"font-gordita-bold"},[_vm._v(" Help us by giving us feedback! ")])])])])])])}]

export { render, staticRenderFns }