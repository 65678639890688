<template>
  <div>
    <!-- Start Header Area -->
    <Header />
    <div class="fancy-hero-feedback bg-doc space-fix">
      <div class="bg-wrapper">
        <div class="container">
          <div class="row">
            <div class="col-xl-9 col-lg-11 col-md-10 m-auto">
              <h2>Feedback</h2>
              <h1 class="font-gordita-bold">
                Help us by giving us feedback!
              </h1>
            </div>
          </div>
        </div>
      </div> <!-- /.bg-wrapper -->
    </div>

    <!-- Start Comment Form  -->
    <div>
      <div class="container">
        <div class="">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form
              class=""
              @submit.prevent="handleSubmit(onSubmit)"
            >
              <v-row>
                <v-col>
                  <div class="input-group-meta form-group mb-60">

                    <p>Name</p>
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="name"
                      rules="required"
                    >
                      <label>
                        <input
                          v-model="formData.name"
                          type="text"
                          placeholder="Name"
                        >
                        <span class="input-error">{{ errors[0] }}</span>
                      </label>
                    </ValidationProvider>
                  </div>

                  <div class="input-group-meta mb-80 sm-mb-70">
                    <p>What features are you enjoying the most? </p>
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="question1"
                    >
                      <label>
                        <textarea
                          v-model="formData.question1"
                          type="text"
                          placeholder="Comment"
                        />
                        <span class="input-error">{{ errors[0] }}</span>
                      </label>
                    </ValidationProvider>
                  </div>

                  <div class="input-group-meta mb-80 sm-mb-70">
                    <p>What functionality would you want to be added to the Heubot Application?</p>
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="question2"
                    >
                      <label>
                        <textarea
                          v-model="formData.question2"
                          placeholder="Comment"
                        />
                        <span class="input-error">{{ errors[0] }}</span>
                      </label>
                    </ValidationProvider>
                  </div>

                  <div class="input-group-meta mb-80 sm-mb-70">
                    <p>Would you Recommended Heubot to a friend or colleague?</p>
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="question3"
                    >
                      <label>
                        <textarea
                          v-model="formData.question3"
                          placeholder="Comment"
                        />
                        <span class="input-error">{{ errors[0] }}</span>
                      </label>
                    </ValidationProvider>
                  </div>

                  <div class="input-group-meta mb-80 sm-mb-70">
                    <p>Are there any additional comments or questions you have about the Heubot Application?  </p>
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="question4"
                    >
                      <label>
                        <textarea
                          v-model="formData.question4"
                          placeholder="Comment"
                        />
                        <span class="input-error">{{ errors[0] }}</span>
                      </label>
                    </ValidationProvider>
                  </div>
                </v-col>

                <v-col cols="12">
                  <div>
                    <button
                      class="theme-btn-home"
                      type="submit"
                      value="submit"
                    >
                      SUBMIT
                    </button>
                  </div>
                </v-col>
              </v-row>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
    <!-- End Comment Form  -->
    <Footer />
  </div>
</template>

<script>
import feather from 'feather-icons'
import { ValidationObserver } from 'vee-validate'
import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import db from '@/store/firestoreDB'
import Header from './Header.vue'
import Footer from './Footer.vue'

export default {
  components: {
    // Footer,
    ValidationObserver,
    ValidationProvider,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    Header,
    Footer,
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      logo: require('../assets/images/logo/logo.png'),
      formData: {
        name: '',
        question1: '',
        question2: '',
        question3: '',
        question4: '',
      },
      items: [
        {
          // eslint-disable-next-line global-require
          thumb: require('../assets/images/blog/bl-big-01.jpg'),
          src: 'https://www.youtube.com/watch?v=ZOoVOfieAF8',
        },
      ],
      metaList: [
        {
          icon: 'clock',
          text: 'May 18, 2020',
        },
        {
          icon: 'user',
          text: 'Fatima',
        },
        {
          icon: 'message-circle',
          text: '15 Comments',
        },
        {
          icon: 'heart',
          text: 'Like',
        },
      ],
      index: null,
    }
  },

  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg()
    },
    onSubmit() {
      const diaryRecord = {
        name: this.formData.name,
        question1: this.formData.question1,
        question2: this.formData.question2,
        question3: this.formData.question3,
        question4: this.formData.question4,
      }
      this.$store.commit('addDiaryRecord', diaryRecord)
      db.collection('writtenDiaryRecord').add(diaryRecord).then(() => {

      })
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notification',
          icon: 'BellIcon',
          text: '👋 Thanks for the feedback',
          variant: 'primary',
        },
      })
    },
  },
}
</script>
<style>
@import 'css/custom.css';
@import 'css/style.css';
@import 'css/custom-animation.css';
@import 'css/framework.css';
@import 'css/menu.css';
@import 'css/responsive.css';

h2 {
  font-family: "gordita" !important;
  font-size: 25px !important;
}

p{
  font-family: "gordita" !important;
  font-size: 22px !important;
}
li{
  font-family: "gordita" !important;
  font-size: 18px !important;
  color: rgb(84, 84, 84);
}
.input-error {
  color: red;
  font-family: "gordita" !important;
}

textarea {
  width:900px !important;
  height:120px !important;
  padding:15px;
  border-radius:15px;
  border:1;
  color: #000;
   border:1px solid rgb(232, 232, 232);
}

input {
  width:900px !important;
  padding:15px;
  border-radius:15px;
  border:0;
    border:1px solid rgb(232, 232, 232);
  height:50px;
}
</style>
